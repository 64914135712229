.thematicListItemWinterCampaign__wrapper {
    width: 100%;
    padding: var(--space-8);
    list-style: none;
}

.thematicListItemWinterCampaign__container {
    display: flex;
    position: relative;
    flex-wrap: nowrap;
    align-items: end;
    width: 100%;
    height: 120px;
    padding: var(--space-8);
    overflow: hidden;
    border-radius: 4px;
}

.thematicListItemWinterCampaign__label {
    display: flex;
    position: relative;
    gap: var(--space-4);
}

.thematicListItemWinterCampaign__label:hover {
    text-decoration: underline;
}

.thematicListItemWinterCampaign__icon {
    width: 20px;
}

.thematicListItemWinterCampaignContainerImage {
    object-fit: cover;
}

@media (min-width: 576px) {
    .thematicListItemWinterCampaign__wrapper {
        max-width: 260px;
    }
}
