.cta {
    margin-left: auto;
}

.thematicsContainer {
    --thematic-section-gutter: var(--space-32);

    display: flex;
    flex-wrap: wrap;
    margin: 0 calc(-0.5 * var(--thematic-section-gutter)) var(--space-48)
        calc(-0.5 * var(--thematic-section-gutter));
}

.thematicSection {
    flex-direction: row;
    width: calc(25% - var(--thematic-section-gutter));
    margin: 0 calc(0.5 * var(--thematic-section-gutter));
}

.thematicTitle {
    display: block;
    padding: var(--space-8) var(--space-24) var(--space-8) 0;
}

.thematicListContainer {
    width: 100%;
}

.thematicList {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
}

.thematicListItem {
    width: 100%;
}

.footer {
    display: flex;
    justify-content: flex-end;
}
