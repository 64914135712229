.nav,
.navNoMenu {
    display: flex;
    position: relative;
    width: 100%;
    height: var(--primary-nav-height);
    padding: 0 var(--space-8);
    color: var(--color-neutral-100);
}

.nav {
    justify-content: space-between;
}

.navNoMenu {
    align-items: center;
    justify-content: center;
}

.navSide {
    display: flex;
    align-items: center;
}

.logo {
    position: relative;
    flex-shrink: 0;
    height: 22px;
    margin: 0 var(--space-8);
}

.dropdownContainer {
    z-index: var(--z-index-appbar);
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
}

.previewModeText {
    position: absolute;
    right: 0;
    bottom: calc(var(--space-24) * -1);
    color: var(--color-blue-800);
    text-shadow:
        2px 0 var(--color-neutral-0),
        -2px 0 var(--color-neutral-0),
        0 2px var(--color-neutral-0),
        0 -2px var(--color-neutral-0),
        1px 1px var(--color-neutral-0),
        -1px -1px var(--color-neutral-0),
        1px -1px var(--color-neutral-0),
        -1px 1px var(--color-neutral-0);
}

@media (min-width: 576px) {
    .nav,
    .navNoMenu {
        padding: 0 var(--space-16);
    }
}

@media (min-width: 992px) {
    .nav,
    .navNoMenu {
        padding: 0 var(--space-24);
    }
}

@media (max-width: 1199.98px) {
    .hiddenMobile {
        display: none;
    }
}

@media (min-width: 1200px) {
    .nav,
    .navNoMenu {
        padding: 0 var(--space-32);
    }

    .navNoMenu {
        justify-content: flex-start;
    }

    .logo {
        margin-right: var(--space-16);
    }

    .burgerMenuButton {
        display: none;
    }
}
